import {toBase64, workingOffAPI} from "../../api/api";

const SET_TASK_ID = "SET_TASK_ID";
const SET_ORDER = "SET_ORDER";
const SET_TIME_FROM = "SET_TIME_FROM";
const SET_TIME_TO = "SET_TIME_TO";
const ADD_CHEQUE = "ADD_CHEQUE";
const REMOVE_CHEQUE = "REMOVE_CHEQUE";
const SET_LOADING = "SET_LOADING";
const SET_MESSAGE = "SET_MESSAGE";
const SET_DATA_EXISTS = "SET_NO_DATA";

var initialState= {
    id: '',
    message: null,
    order: {
        id:'',
        date:'11.12.21',
        number:'1556',
        address:'Москва, Красная Площадь, д.1',
        link:'http://yandex.ru',
        first_name: 'Иван',
        middle_name: 'Иванович',
        work_off: false
    },
    time_from:null,
    time_to:null,
    is_loading:false,
    chequeList:[],
    is_data_exists: false

};

const sendMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDER:
            return {
                ...state,
                order: {...action.value, id: action.id}
            };
            case SET_TASK_ID:
            return {
                ...state,
                id: action.value
            };
        case SET_TIME_FROM:
            return {
                ...state,
                time_from:  action.value
            };
        case SET_TIME_TO:
            return {
                ...state,
                time_to:  action.value
            };

        case SET_LOADING:
            return {
                ...state,
                is_loading:  action.value
            };

        case SET_DATA_EXISTS:
            let st = {...state};
            st.is_data_exists = action.value;
            return st;
        case SET_MESSAGE:
            return {
                ...state,
                message:  {text:action.text, is_error: action.is_error}
            };
        case  ADD_CHEQUE:
            return {
                ...state,
                chequeList : [...state.chequeList, {file:action.file,sum:action.sum}]
            };
         case  REMOVE_CHEQUE:
            return {
                ...state,
                chequeList : state.chequeList.filter( (val, index) => index !== action.value)
            };
        default:
            return state;
    }
};

export const setTaskId = (id) => ({type: SET_TASK_ID, value: id});
export const setOrder = (order,id) => ({type: SET_ORDER, value: order,  id});
export const setTimeFrom = (value) => ({type: SET_TIME_FROM, value });
export const setTimeTo = (value) => ({type: SET_TIME_TO, value });
export const addCheque = (file,sum) => ({type: ADD_CHEQUE, file: file.file , sum });
export const removeCheque = (index) => ({type: REMOVE_CHEQUE, value: index });
export const setLoading = (value) => ({type: SET_LOADING, value });
export const setMessage = (text,is_error) => ({type: SET_MESSAGE, text, is_error });
export const setDataExists = (value) => ({type: SET_DATA_EXISTS, value });


export const submitForm = () => async (dispatch, getState)=>{
    let state = getState().sendMessageReducer;
    dispatch(setLoading(true));
    let data = { id: state.order.id, time_from: state.time_from, time_to: state.time_to, cheques:[]};
    for (let i = 0; i < state.chequeList.length; i++) {
        let response = await postFile(state.chequeList[i].file);
        if (response && response.id)
            data.cheques.push({file_id:response.id, amount: +state.chequeList[i].sum})
    }
    let answer = await workingOffAPI.sendSubmitData(data);
    dispatch(setLoading(false));
    if (!answer || !answer.data || !answer.data.hasOwnProperty('status') )
        dispatch(setMessage( "Ошибка передачи данных, попробуйте повторить попытку позже !",  true));
    else
        if (+answer.data.status !== 0) {
            dispatch(setMessage( answer.data.errormessage, true));
        }
    else
        dispatch(setMessage( "Спасибо, ваше сообщение успешно отправлено !", false ));
};

async function postFile(fileObject){

    const data = await toBase64(fileObject);
    let response = await workingOffAPI.postFiles({
        name: fileObject.name,
        data: data.split(',')[1]
    });
    if (response.status === 200 && response.data && response.id) {
        return new Promise( resolve => resolve(response.data) ) ;
    } else {
        return null;
    }
};


export const loadOrder = (id) => async (dispatch, getState)=>{
    dispatch(setLoading(true));
    let response = await workingOffAPI.getDataInfo(id);
    if (response.data) {
       if (response.data.date && response.data.date !== ""){
           dispatch(setOrder(response.data,id));
           dispatch(setDataExists(true));
           if (response.data.work_off) {
               dispatch(setMessage('Информация по отработке уже была введена!', true));
           }
       }
    }
    dispatch(setLoading(false));
};




export default sendMessageReducer;
