import React from 'react';

const MessageInfo = ({message}) => {

    return (
        <>
            {message ?
                message.is_error ?
                        <div className='messageInfo '>
                            {message.text}
                        </div>
                        :
                        <div className='messageInfo messageGood'>
                            {message.text}
                        </div>
                :
                        <div className='messageInfo'>
                            Идентификатор заказа не существует! <br/> Если вы уверенны, что ссылка корректная, то попробуйте загрузить
                            страницу позднее.
                        </div>
            }
        </>
    )
};

export default MessageInfo;
