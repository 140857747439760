import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {connect, Provider} from "react-redux";
import store from "./redux/store";
import SendMessageContainer from "./components/SendMessage/SendMessageContainer";

function App() {
  return (
      <Switch>
        <Route exact path='/' component={SendMessageContainer}/>
      </Switch>
  );

}


const mapStateToProps = (state) => ({});

let AppContainer = connect(mapStateToProps, {})(App);

const CheckoutApp = () => {
  return <Provider store={store}>
            <BrowserRouter basename="/">
                  <AppContainer/>
            </BrowserRouter>
        </Provider>;
};

export default CheckoutApp;
