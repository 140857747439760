import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from "redux-thunk";
import sendMessageReducer from "./reducers/sendMessageReducer";


let reducers = combineReducers({
    sendMessageReducer
});

const composeEnhancers = window.REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||compose;
let store = createStore(reducers, composeEnhancers(composeWithDevTools(applyMiddleware(thunkMiddleware))));

// store.dispatch(initManualSelectData());

export default store;
