import React from 'react';
import {connect } from "react-redux";
import SendMessageForm from "./SendMessageForm";
import {
    addCheque,
    loadOrder,
    removeCheque, setDataExists,
    setTimeFrom,
    setTimeTo,
    submitForm
} from "../../redux/reducers/sendMessageReducer";
import MessageInfo from "../MessageInfo/MessageInfo";
import WindowLoader from "../Loader/WindowLoader";



class ClientFormContainer extends React.Component {

    componentDidMount() {
        let url = new URL(window.location.href);
        let id = url.searchParams.get('id');
        if (id) this.props.loadOrder(id);

    }

    chequeFileDel = (index) => {
      this.props.removeCheque(index);
    };
    chequeFileAdd = (file, sum) => {
      this.props.addCheque(file, sum);
    };

    setTime = (value,is_from) =>{
        if (is_from) this.props.setTimeFrom(value);
        else  this.props.setTimeTo(value)
    };

    render() {
        return (
            <div className="mainContainer">
                <div className="content">

                  {
                  this.props.isLoading ?
                      <WindowLoader/> :
                  this.props.message ?
                      <MessageInfo  message={this.props.message} /> :
                  this.props.showErrorMessage ?
                      <MessageInfo   /> :
                  <SendMessageForm
                      dateOrder={this.props.order.date}
                      firstAddress={this.props.order.address}
                      fullName={this.props.order.first_name + ' ' + this.props.order.middle_name}
                      linkOrder={this.props.order.link}
                      numberOrder={this.props.order.number}
                      timeFrom={this.props.time_from}
                      timeTo={this.props.time_to}
                      listCheques={this.props.chequeList}
                      onChequeFileAdd={this.chequeFileAdd}
                      onRemoveCheque={this.chequeFileDel}
                      onSetTimeFrom={(value) => this.setTime(value, true)}
                      onSetTimeTo={(value) =>  this.setTime(value, false)}
                      onSubmitForm={() =>  this.props.submitForm()}

                  /> }
                </div>
            </div>
        )
    }
}

let mapStateToProps = (state) => ({
    order: state.sendMessageReducer.order,
    time_to: state.sendMessageReducer.time_to,
    time_from: state.sendMessageReducer.time_from,
    chequeList: state.sendMessageReducer.chequeList,
    showErrorMessage: !state.sendMessageReducer.is_data_exists,
    isLoading: state.sendMessageReducer.is_loading,
    message: state.sendMessageReducer.message,
});

export default connect(mapStateToProps, {setTimeFrom, setTimeTo, addCheque, removeCheque, submitForm, loadOrder, setDataExists})(ClientFormContainer);
