import * as axios from "axios";
import axiosRetry from 'axios-retry';
import {apiBaseUrl} from "../../src/deployment";

axiosRetry(axios, {retries: 50});

const instance = axios.create({
    baseURL: apiBaseUrl,
});


export const workingOffAPI = {

   async getDataInfo(id) {
       return instance.get(`/order-info?id=${id}`)
           .then(response => response)
           .catch(error => error);
   },

   async sendSubmitData(data){

        // console.log(data);
        // return  new Promise( resolve => resolve({status:200} )) ;

        return instance.post('/work-off', data)
            .then(response => response)
            .catch(error => error);
    },

    postFiles(data) {
        return instance.post('/work-off-files', data)
            .then(response => response)
            .catch(error => error);
    },
};

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
