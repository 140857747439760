import React from 'react';
import Loader from 'react-loader-spinner';

const WindowLoader = () => {
    return (
            <div className='messageInfo loading'>
                <Loader type="Puff" color="#5364FF" height={80} width={80}/>
                <div className='loadingText'>
                    идет загрузка данных
                </div>
            </div>
    );
}

export default WindowLoader;
