import React, {useState} from "react";


const SendMessageForm = ({fullName, dateOrder, numberOrder, firstAddress, linkOrder, listCheques, onChequeFileAdd, onRemoveCheque,
                          timeFrom, timeTo, onSetTimeFrom, onSetTimeTo, onSubmitForm }) => {
    const [isShowFormAddCheque, setShowFormAddCheque] = useState(false);

    const [cheque, setCheque] = useState(null);
    const [chequeSum, setChequeSum] = useState(0);
    const chequePhoto = React.createRef();




    const showFormAddCheque = (value) => { setShowFormAddCheque(value);};

    const onAddCheque = () => {

        onChequeFileAdd(cheque,chequeSum);

        setCheque(null);
        setShowFormAddCheque(false);
    };

    const canSend = timeFrom && timeTo;
    return (
        <>
            <div className="sendMessageForm">
                <div className="title">Здравствуйте, {fullName}!</div>
                <div className="line lineOrder">{dateOrder} Вы выполняли заказ  № {numberOrder}</div>
                <div className="line lineLeft"><span className="leftSide">Адрес подачи:</span><br/>{firstAddress}<br/></div>
                <div className="line lineLeft"><span className="leftSide">Маршрут следования:</span><br/><a href={linkOrder}>посмотреть по ссылке</a><br/></div>
                <div className="line lineBigMargin"><span className="leftSide">{fullName},</span><br/> заполните пожалуйста следующие данные:</div>
                <div className="data">
                <div className="line lineLeft lineFlex">
                    <div className="leftSide">Время начала заказа:</div>
                    <div><input type="time" onChange={e => onSetTimeFrom(e.target.value)} /></div>
                </div>
                <div className="line lineLeft lineFlex">
                    <div className="leftSide">Время окончания заказа</div>
                    <div><input type="time"  onChange={e=> onSetTimeTo(e.target.value)} /></div>
                </div>
                    {listCheques && listCheques.length>0 &&
                    <div>
                        {  listCheques.map( (val,index) => (
                            <div key={"vv"+index} className="chequeRow">
                                <div>Чек на сумму: {val.sum} руб</div>
                                <div onClick={() => onRemoveCheque(index)}> <span  className="closeButton">X</span> </div>
                            </div>
                            ) )  }
                    </div>
                    }
                </div>

                {isShowFormAddCheque &&
                <div className="popupForm">
                    <div className="line closeContainer"><span onClick={() => showFormAddCheque(false)} className="closeButton">X</span></div>
                    <div className="line">
                        <input type="number" placeholder="Введите сумму чека" onChange={e=> setChequeSum(e.target.value)} />
                        {cheque && cheque.file && <div className="note">{cheque.file.name}</div>}
                        <input type="file" id="photo-attach" ref={chequePhoto} accept="image/*"
                           onChange={(e) => {
                               if (e.target.files.length !== 0) {
                                   setCheque({file: e.target.files[0]});
                               }
                           }} />
                    </div>
                    <div className="line lineFlex">
                        {!cheque &&  <div className={chequeSum > 0 ? "button width200px" : "button width200px disabled" }
                                          onClick={  e  => chequePhoto.current.click(e)  } >Прикрепить чек</div>
                        }
                        {cheque &&   <div className="button button-green" onClick={ ()=> onAddCheque()}  >Сохранить</div>}
                    </div>
                </div>
                }

                <div className="buttonAndNote">
                    <div className={isShowFormAddCheque ? "button disabled" :"button"} onClick={() => showFormAddCheque(!isShowFormAddCheque)}>Добавить чек</div>
                    <span className="note">* если был платный въезд</span>
                </div>

                <div className="buttonAndNote">
                    <div className={ canSend ? "button button-green" : "button disabled"} onClick={onSubmitForm}>ОТПРАВИТЬ</div>
                </div>

            </div>
        </>);
};
export default SendMessageForm;
