//.....................................localhost.................................
// export const listOfBenefitsForPaymentLink = "https://docs.google.com/document/d/1paIbso99hfjSQwu4vWpKxqzpPBSw_f1NdC-cqNBhnN4/edit?usp=sharing";
// export const RoutesEndpointForMapLink = "https://www.tg-group.ru/wp-json/fz/v1";
// export const apiBaseUrl = 'https://www.tg-group.ru/wp-json/fz/v1';
const host = window.location.host.replace('www.','');
// const apiHost = host.indexOf('localhost')>=0 ? 'tg-group.ru' :
//          host.indexOf('-off.one.gruzuber.ru') >=0? 'tg-group.ru' :
//          host.indexOf('-off.mor.gruzuber.ru') >=0? 'citycarrier.ru': host;

const apiHost = "progrecctk.ru";
export const apiBaseUrl = `https://api2.${apiHost}/PublicDriversAPI`;
